<template>
  <div class="box f_color1 font2">
    <van-nav-bar :title="'数据概览'" left-text="" left-arrow
                 @click-left="onClickLeft" @click-right="onClickRight">
      <template #right>
        <div class="nav-bar-right">
          <template v-if="this.groupInfo && this.groupInfo.type === 0">
            <a ref="downloadLink" :href="downloadUrl" target="downloadFile"></a>
            <van-button type="primary" class="custom-button" icon="download" plain @click="downloadFile">导出
            </van-button>
            <iframe style="display: none;" name="downloadFile"></iframe>
          </template>
        </div>
      </template>
    </van-nav-bar>
    <div v-if="!!groupInfo">
      <van-search class="search" v-model="search" @search="onSearch" shape="round" show-action placeholder="输入用户ID">
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
      <div class="search-type">
        <div class="id-type">
          <van-radio-group v-model="checked" direction="horizontal">
            <van-radio name="0" icon-size="14px" class="font1">用户ID</van-radio>
            <van-radio name="1" icon-size="14px" class="font1">上级ID</van-radio>
          </van-radio-group>
        </div>
        <van-dropdown-menu v-if="this.groupInfo && this.groupInfo.type === 0" class="income-type"
                           active-color="#1989fa">
          <van-dropdown-item v-model="incomeType" :options="incomeTypes" @change="onIncomeTypeChanged"/>
        </van-dropdown-menu>
      </div>
      <div class="search-time">
        <div class="time ltime" @click="onTimeBeginTapped">&nbsp;&nbsp;{{ timeBeginDisplay }}&nbsp;&nbsp;</div>
        <span>&nbsp;至&nbsp;</span>
        <div class="time" @click="onTimeEndTapped">&nbsp;&nbsp;{{ timeEndDisplay }}&nbsp;&nbsp;</div>
      </div>
      <van-list class="list font2" v-model:loading="loading" :immediate-check="false" :finished="finished"
                finished-text="没有更多了" @load="onLoad">
        <div class="listItem" v-for="(li, i) in list" :key="i">
          <div class="body">
            <div class="person-header">
              <div class="avatar">
                <img :src="li.avatar" alt=""></div>
              <div class="left">
                <div class="name">{{ li.userInfo.nickName }}</div>
                <div class="uid" @click="onUidTapped(li)">ID: {{ li.userId }}</div>
                <div class="uid">加入时间: {{ !!li.joinTime ? getDisplayTime(li.joinTime) : '--' }}</div>
              </div>
            </div>

            <div style="text-align: center">
              <div class="d-item-inline" style="padding-left: 10px;margin-top: 15px;display: inline-block;width: 30%">
                <div class="t-time">{{ (li.income) }}</div>
                <div class="t-desc">总收益</div>
              </div>
              <div class="d-item-inline" style="padding-left: 10px;margin-top: 15px;display: inline-block;width: 30%"
                   @click="onInviteCodeTapped(li)">
                <div class="t-time">{{ (!!li.code ? li.code : '--') }}</div>
                <div class="t-desc">邀请码</div>
              </div>
              <div class="d-item-inline" style="padding-left: 10px;margin-top: 15px;display: inline-block;width: 30%"
                   @click="onParentIdTapped(li)">
                <div class="t-time">{{ (!!li.parentId ? li.parentId : '--') }}</div>
                <div class="t-desc">上级ID</div>
              </div>
            </div>
            <div style="text-align: center">
              <div class="d-item-inline" style="padding-left: 10px;margin-top: 15px;display: inline-block;width: 30%">
                <div class="t-time">{{ (li.my) }}</div>
                <div class="t-desc desc">私聊收益</div>
              </div>
              <div class="d-item-inline" style="padding-left: 10px;margin-top: 15px;display: inline-block;width: 30%">
                <div class="t-time">{{ (li.voice) }}</div>
                <div class="t-desc desc">音频收益</div>
              </div>
              <div class="d-item-inline" style="padding-left: 10px;margin-top: 15px;display: inline-block;width: 30%">
                <div class="t-time">{{ (li.video) }}</div>
                <div class="t-desc">视频收益</div>
              </div>
            </div>
            <div style="text-align: center">
              <div class="d-item-inline" style="padding-left: 10px;margin-top: 10px;display: inline-block;width: 30%">
                <div class="t-time">{{ (li.gift) }}</div>
                <div class="t-desc">礼物收益</div>
              </div>
              <div class="d-item-inline" style="padding-left: 10px;margin-top: 10px;display: inline-block;width: 30%">
                <div class="t-time">{{ (li.heart) }}</div>
                <div class="t-desc">心动收益</div>
              </div>
              <div class="d-item-inline" style="padding-left: 10px;margin-top: 10px;display: inline-block;width: 30%">
                <div class="t-time">{{ (!!li.itemNum ? li.itemNum : '0') }}</div>
                <div class="t-desc">邀请充值分成</div>
              </div>
            </div>
            <div style="text-align: center">
              <div class="d-item-inline" style="padding-left: 10px;margin-top: 10px;display: inline-block;width: 30%">
                <div class="t-time">
                  {{ (!!li.heartbeatNum ? li.heartbeatNum : '0') }}/{{ (!!li.heartNumRecv ? li.heartNumRecv : '0') }}
                </div>
                <div class="t-desc">心动发送/接收(次)</div>
              </div>
              <div class="d-item-inline" style="padding-left: 10px;margin-top: 10px;display: inline-block;width: 30%">
                <div class="t-time">
                  {{ (!!li.voiceCount ? li.voiceCount : '0') }}/{{ (!!li.voiceTime ? li.voiceTime : '0') }}
                </div>
                <div class="t-desc">语音通话(次/分钟)</div>
              </div>
              <div class="d-item-inline" style="padding-left: 10px;margin-top: 10px;display: inline-block;width: 30%">
                <div class="t-time">
                  {{ (!!li.videoCount ? li.videoCount : '0') }}/{{ (!!li.videoTime ? li.videoTime : '0') }}
                </div>
                <div class="t-desc">视频通话(次/分钟)</div>
              </div>
            </div>
          </div>
          <div class="foot">
            <div class="left lines_right" @click="onCopyUserIdTapped(li)">
              复制用户ID
            </div>
            <div class="right" @click="onIncomeTapped(li)">
              查看流水
            </div>
          </div>
        </div>
      </van-list>
      <van-popup v-model:show="showDatePicker" position="bottom" round>
        <van-datetime-picker v-model="datePicker" type="date" title="选择日期" :min-date="minDate" :max-date="maxDate"
                             @confirm="onDatePickerConfirm" @cancel="onDatePickerCancel"/>
      </van-popup>
    </div>
    <div v-if="!!groupInfo" class="bottom">
      <div class="part">
        <div class="value">{{ totalIncome }}</div>
        <div class="key">{{ this.groupInfo ? (this.groupInfo.type === 0 ? '总收益' : '总充值') : '' }}</div>
      </div>
      <div class="part">
        <div class="value">{{ totalUserChatIncome }}</div>
        <div class="key">用户聊天收益</div>
      </div>
      <div class="part">
        <div class="value">{{ userInviteRewards }}</div>
        <div class="key">用户邀请收益</div>
      </div>
      <div class="part">
        <div class="value">{{ totalParentIncome }}</div>
        <div class="key">上级分成</div>
      </div>
      <div class="part">
        <div class="value">{{ groupChatRewards }}</div>
        <div class="key">组长聊天分成</div>
      </div>
      <div class="part">
        <div class="value">{{ groupInviteRewards }}</div>
        <div class="key">组长邀请分成</div>
      </div>
    </div>
    <Mask v-if="marke"></Mask>
  </div>
</template>
<script>
// import { wxqq } from '../utils/utils'
import Mask from '../components/Mask'
import routerUtil from '../utils/routerUtil'
import {Toast} from "vant"
import moment from 'moment'
import copy from "copy-to-clipboard";

export default {
  name: 'Income',
  data() {
    return {
      show: true,
      search: '',
      downloadUrl: '',
      checked: '0',
      incomeType: 0,
      incomeTypes: [
        {text: '全部类型', value: 0},
        {text: '私聊送礼', value: 22},
        {text: '心动送礼', value: 63},
        {text: '普通聊天', value: 52},
        {text: '语音', value: 37},
        {text: '视频', value: 55},
        {text: '邀请充值分成', value: 66},
      ],
      timeBegin: 0,
      timeEnd: 0,
      showDatePicker: false,
      datePickerMode: 0,
      datePicker: null,
      minDate: null,
      maxDate: null,
      groupInfo: null,
      searchUid: null,
      searchType: 0,
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      totalIncome: 0,
      totalUserChatIncome: 0,
      userInviteRewards: 0,
      totalParentIncome: 0,
      groupChatRewards: 0,
      groupInviteRewards: 0,
      marke: false,
      isDownloaded: false // 是否已下载标志
      , params: ""
    }
  },
  components: {
    Mask,
  },
  computed: {
    timeBeginDisplay() {
      const date = new Date(this.timeBegin);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${date.getFullYear()}/${month > 9 ? month : '0' + month}/${day > 9 ? day : '0' + day}`;
    },
    timeEndDisplay() {
      const date = new Date(this.timeEnd);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${date.getFullYear()}/${month > 9 ? month : '0' + month}/${day > 9 ? day : '0' + day}`;
    }
  },
  mounted() {
    if (this.$route.params.userId) {
      this.search = this.$route.params.userId
      this.searchUid = parseInt(this.search);
    }
    this.reqInfo()
    // this.marke = wxqq()
  },
  methods: {
    prepareDownload() {
      const requestData = {
        groupId: this.groupInfo.id,
        ownerId: this.groupInfo.ownerId,
        type: this.searchType,
        userId: this.searchUid == null ? 0 : this.searchUid,
        transType: this.incomeType,
        startTime: this.timeBegin,
        endTime: this.timeEnd
      };

      this.params = new URLSearchParams(requestData).toString();


      // 阻止默认跳转行为

      // 在这里编写准备下载的逻辑
      // 例如：从后端获取文件下载链接等
      const downloadUrl = 'http://localhost:8088/manage/stat/user/gincomelistout/x.xls?' + this.params; // 替换为实际的下载链接
      this.downloadUrl = downloadUrl;

      this.$refs.downloadLink.click(); // 模拟用户点击下载链接


    },
    prepareDownload1() {
      const requestData = {
        groupId: this.groupInfo.id,
        ownerId: this.groupInfo.ownerId,
        type: this.searchType,
        userId: this.searchUid == null ? 0 : this.searchUid,
        transType: this.incomeType,
        startTime: this.timeBegin,
        endTime: this.timeEnd
      };

      this.params = new URLSearchParams(requestData).toString();


      // 阻止默认跳转行为

      // 在这里编写准备下载的逻辑
      // 例如：从后端获取文件下载链接等
      const downloadUrl = 'http://localhost:8088/manage/stat/user/gincomelistout/x.xls?' + this.params; // 替换为实际的下载链接
      this.downloadUrl = downloadUrl;


    },

    onUidTapped(li) {
      copy(li.userId);
      Toast('用户ID已复制');
    },
    onInviteCodeTapped(li) {
      copy(li.code);
      Toast('邀请码已复制');
    },
    onParentIdTapped(li) {
      if (!li.parentId) {
        return
      }
      copy(li.parentId);
      Toast('上级ID已复制');
    },
    onCopyUserIdTapped(li) {
      this.onUidTapped(li)
    },
    onIncomeTapped(li) {
      this.$router.push({
        name: 'Income',
        params: {
          userId: li.userId
        }
      });
    },

    downloadFile() {
      this.$request.postJson(this.$mUrl + '/manage/stat/user/gincomelistout', {
        groupId: this.groupInfo.id,
        ownerId: this.groupInfo.ownerId,
        type: this.searchType,
        userId: this.searchUid == null ? 0 : this.searchUid,
        transType: this.incomeType,
        startTime: this.timeBegin,
        endTime: this.timeEnd
      })
          .then(buffer => {


            const encoder = new TextEncoder();
            const xmlData = encoder.encode(buffer.data);
            const blob = new Blob([xmlData], {type: 'application/octet-stream'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            let now = new Date();
            let month = now.getMonth() + 1;
            let t = '' + month
            t += now.getDate()
            t += now.getHours()
            let name = '成员收益流水概览' + t + '.xls'
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
          });

    }

    ,

    reqInfo() {
      this.$request.postJson('/api/user/pay_group/info', {})
          .then((res) => {
            if (res.data.code == 0) {
              this.groupInfo = res.data.data;
              if (!this.groupInfo) {
                Toast('暂无推广组信息');
                routerUtil.back()
                return;
              }
              this.prepareDownload1()
              switch (this.groupInfo.period) {
                case 1:
                  this.timeBegin = moment().startOf('day').valueOf();
                  this.timeEnd = moment().endOf('day').add(1, 'days').valueOf();
                  break;
                case 2:
                  moment
                  this.timeBegin = moment().startOf('day').valueOf();
                  this.timeEnd = moment().endOf('day').add(1, 'days').valueOf();
                  break;
                case 3:
                  this.timeBegin = moment().startOf('day').valueOf();
                  this.timeEnd = moment().endOf('day').add(1, 'days').valueOf();
                  break;
              }
              this.getList()
              this.getStatistics()
            }
          })
    },
    getList(type) {
      let lastId = ''
      if (type && this.list.length > 0) {
        lastId = this.list[this.list.length - 1].id
      }
      this.$request.postJson(this.$mUrl + '/manage/stat/user/gincomelist', {
        groupId: this.groupInfo.id,
        ownerId: this.groupInfo.ownerId,
        type: this.searchType,
        userId: this.searchUid == null ? 0 : this.searchUid,
        transType: this.incomeType,
        startTime: this.timeBegin,
        endTime: this.timeEnd,
        lastId: lastId,
        pageSize: 15
      }).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data.length == 0) {
            this.loading = false;
            this.refreshing = false
            this.finished = true;
            return
          }
          const uids = [];
          for (let log of res.data.data) {
            uids.push(log.userId);
          }
          this.$request.post('/api/user/info/userInfo', {userIds: JSON.stringify(uids)})
              .then((res1) => {
                this.loading = false;
                this.refreshing = false
                if (res1.data.code == 0) {
                  console.log(res1.data)
                  const userMap = new Map();
                  for (let user of res1.data.data.users) {
                    if (user.code == 0) {
                      userMap.set(user.userId, user);
                    } else {
                      Toast("请求用户基本信息失败")
                      return;
                    }
                  }
                  for (let log of res.data.data) {
                    log.userInfo = userMap.get(log.userId);
                  }
                  this.list.push(...res.data.data)
                  this.finished = res.data.data.length < 15
                }
              })
        }
      })
    }
    ,
    getStatistics() {
      this.$request.postJson(this.$mUrl + '/manage/stat/user/getIncomeTotalList', {
        groupId: this.groupInfo.id,
        startTime: this.timeBegin,
        endTime: this.timeEnd,
      }).then((res) => {
        if (res.data.code == 0) {
          this.totalIncome = res.data.data[0].totalIncome;
          this.totalUserChatIncome = res.data.data[0].totalUserChatIncome;
          this.userInviteRewards = res.data.data[0].userInviteRewards;
          this.totalParentIncome = res.data.data[0].totalParentIncome;
          this.groupChatRewards = res.data.data[0].groupChatRewards;
          this.groupInviteRewards = res.data.data[0].groupInviteRewards;
        }
      })
    }
    ,
    onClickLeft() {
      routerUtil.back()
    }
    ,
    onClickRight() {
    }
    ,
    onSearch() {
      if (this.search == "") {
        this.searchUid = null
      } else {
        if (isNaN(Number(this.search, 10))) {
          Toast("请输入用户ID")
          return
        }
        const uid = parseInt(this.search);
        this.searchUid = uid;
      }
      this.searchType = parseInt(this.checked)
      this.list = [];
      this.onRefresh()
      this.getStatistics()
    }
    ,
    onIncomeTypeChanged() {
      this.list = [];
      this.onRefresh()
      this.getStatistics()
    }
    ,
    onTimeBeginTapped() {
      this.datePickerMode = 1;
      this.datePicker = new Date(this.timeBegin);
      this.minDate = new Date(2020, 0, 1);
      this.maxDate = new Date();
      this.showDatePicker = true;
    }
    ,
    onTimeEndTapped() {
      this.datePickerMode = 2;
      this.datePicker = new Date(this.timeEnd);
      this.minDate = new Date(this.timeBegin);
      this.maxDate = new Date();
      this.showDatePicker = true;
    }
    ,
    onDatePickerConfirm(value) {
      switch (this.datePickerMode) {
        case 1:
          this.timeBegin = moment(value).startOf('day').valueOf();
          if (this.timeEnd < this.timeBegin) {
            this.timeEnd = moment(value).endOf('day').valueOf();
          }
          break;
        case 2:
          this.timeEnd = moment(value).endOf('day').valueOf();
          break;
      }
      this.datePickerMode = 0;
      this.showDatePicker = false;
      this.list = [];
      this.onRefresh()
      this.getStatistics()
    }
    ,
    onDatePickerCancel() {
      this.datePickerMode = 0;
      this.showDatePicker = false;
    }
    ,
    onLoad() {
      this.getList(true)
    }
    ,
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList()
    }
    ,
    getDisplayTime(time) {
      const date = new Date(time);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const min = date.getMinutes();
      const sec = date.getSeconds();
      return `${date.getFullYear()}-${month > 9 ? month : '0' + month}-${day > 9 ? day : '0' + day} ${hour > 9 ? hour : '0' + hour}:${min > 9 ? min : '0' + min}:${sec > 9 ? sec : '0' + sec}`;
    }
  },
}
</script>
<style scoped lang="less">
.custom-button {
  background-color: #00C853;
  height: 30px;
  width: 70px;
  color: #ffffff;
}

.box {
  width: 100%;
  height: 100%;

  .search {
    padding-bottom: 0;
  }

  background: #ecf0f4;

  .search-type {
    height: 40px;

    .id-type {
      display: inline-block;
      padding: 10px 12px;
    }

    .income-type {
      float: right;
      width: 100px;
      margin-right: 10px;
      background: #ecf0f4;

      /deep/ .van-dropdown-menu__bar {
        box-shadow: none;
        //height: 40px;
        height: 30px;
        margin-top: 3px;
      }
    }
  }

  .search-time {
    width: 100%;
    padding-bottom: 10px;
    font-size: 14px;
    height: 30px;
    line-height: 30px;

    .ltime {
      margin-left: 12px;
    }

    .time {
      display: inline-block;
      background-color: #00000010;
      border-radius: 4px;
    }
  }
}

.t-desc {
  color: #bebaba;
}

.list {
  position: absolute;
  width: 100%;
  padding-bottom: 50px;
  background-color: #ecf0f4;

  .listItem {
    width: 95%;
    border-radius: 10px;
    margin: 0 auto;
    //height: 100px;
    margin-top: 10px;
    background-color: #fff;
    padding-top: 10px;

    .body {
      width: 100%;
      //height: 70px;

      .left {
        display: inline-block;
        padding-left: 20px;

        .sex {
          background: deeppink;
          color: #fff;
          padding-left: 8px;
          padding-right: 8px;
          margin-left: 2px;
          border-radius: 10px;
          font-size: 12px;
        }

        //.sex{
        //  background: deeppink;
        //  color: #fff;
        //  padding-left: 8px;
        //  padding-right: 8px;
        //  margin-left: 2px;
        //  border-radius: 10px;
        //  font-size: 12px;
        //}
        .name {
          padding-top: 15px;
          font-size: 16px;
          height: 16px;
          line-height: 16px;
          color: #000;
        }

        .uid {
          padding-top: 10px;
          font-size: 14px;
          height: 14px;
          line-height: 14px;
          color: #787878;
        }
      }

      .right-header {
        display: inline-block;
        width: calc(100% / 10);
        height: 50px;
        margin-left: 6%;

        .key {
          margin-top: 15px;
          font-size: 16px;
          height: 12px;
          line-height: 16px;
          color: #000;
          text-align: center;
        }

        .value {
          padding-top: 10px;
          font-size: 14px;
          height: 14px;
          line-height: 14px;
          color: #787878;
          text-align: center;
        }
      }

      .num {
        float: right;
        padding-right: 20px;
        font-size: 14px;
        height: 70px;
        line-height: 70px;
        font-weight: bold;
        color: #000;
      }
    }


    .foot {
      position: relative;
      width: 100%;
      height: 48px;
      font-size: 14px;
      line-height: 48px;

      .left {
        display: inline-block;
        width: calc(100% / 2);
        color: #6b94d9;
        text-align: center;
      }

      .right {
        display: inline-block;
        width: calc(100% / 2);
        color: #f29a38;
        text-align: center;
      }

    }
  }
}

.bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #f2f2f2;
  border-radius: 20px 20px 0 0;

  .part {
    display: inline-block;
    width: calc(100% / 3);
    height: 50px;

    .key {
      margin-top: 6px;
      font-size: 12px;
      height: 12px;
      line-height: 12px;
      color: #787878;
      text-align: center;
    }

    .value {
      margin-top: 8px;
      font-size: 14px;
      font-weight: bold;
      height: 14px;
      line-height: 14px;
      color: #000;
      text-align: center;
    }
  }
}

.van-pull-refresh {
  height: 100% !important;
  overflow: auto !important;
}

.van-list {
  height: auto !important;
}

.person-header {
  margin-left: 10px;
}

.avatar {
  width: 50px;
  display: inline-block;
}

.avatar img {
  border-radius: 50px;
  width: 100%;
}
</style>
